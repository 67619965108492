@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?subset_id=2&fvd=n3&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?subset_id=2&fvd=n3&v=3)
      format("woff"),
    url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?subset_id=2&fvd=n3&v=3)
      format("opentype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?subset_id=2&fvd=n4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?subset_id=2&fvd=n4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?subset_id=2&fvd=n4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?subset_id=2&fvd=n5&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?subset_id=2&fvd=n5&v=3)
      format("woff"),
    url(https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?subset_id=2&fvd=n5&v=3)
      format("opentype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?subset_id=2&fvd=n6&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?subset_id=2&fvd=n6&v=3)
      format("woff"),
    url(https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?subset_id=2&fvd=n6&v=3)
      format("opentype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?subset_id=2&fvd=n7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?subset_id=2&fvd=n7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?subset_id=2&fvd=n7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/849347/000000000000000000010093/27/l?subset_id=2&fvd=i3&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/849347/000000000000000000010093/27/d?subset_id=2&fvd=i3&v=3)
      format("woff"),
    url(https://use.typekit.net/af/849347/000000000000000000010093/27/a?subset_id=2&fvd=i3&v=3)
      format("opentype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/l?subset_id=2&fvd=i4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/d?subset_id=2&fvd=i4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/a?subset_id=2&fvd=i4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/1eb35a/000000000000000000010090/27/l?subset_id=2&fvd=i5&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/1eb35a/000000000000000000010090/27/d?subset_id=2&fvd=i5&v=3)
      format("woff"),
    url(https://use.typekit.net/af/1eb35a/000000000000000000010090/27/a?subset_id=2&fvd=i5&v=3)
      format("opentype");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}
@font-face {
  font-family: futura-pt;
  src: url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/l?subset_id=2&fvd=i7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/d?subset_id=2&fvd=i7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/a?subset_id=2&fvd=i7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

body {
  margin: 0;
  font-family: futura-pt, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative; /* for the footer to move with the page size */
  min-height: 100%; /* for the footer to be at the bottom */
  padding-bottom: 10rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
