.App-logo {
  width: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}
